import React, { useEffect, useState } from "react";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Table } from "@tiptap/extension-table";
import { TableRow } from "@tiptap/extension-table-row";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableCell } from "@tiptap/extension-table-cell";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { createTyc, updateTyc } from "services/tyc.service";
import "./styles.css";

export const MenuBar = ({ editor }) => {
  const [setLink, setSetLink] = useState(() => () => {});

  useEffect(() => {
    if (!editor) return;

    const handleSetLink = () => {
      const previousUrl = editor.getAttributes("link")?.href || "";
      const url = window.prompt("Ingresa la URL", previousUrl);

      if (url === null) return;
      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    };

    setSetLink(() => handleSetLink);
  }, [editor]);

  if (!editor) return null;

  return (
    <div className="control-group">
      <div className="button-group">
        <button
          type="button"
          onClick={() =>
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          }
          className="buttonTipTap"
        >
          Insertar tabla
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addColumnBefore().run()}
          disabled={!editor.can().addColumnBefore()}
          className="buttonTipTap"
        >
          Agregar columna antes
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addColumnAfter().run()}
          disabled={!editor.can().addColumnAfter()}
          className="buttonTipTap"
        >
          Agregar columna después
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteColumn().run()}
          disabled={!editor.can().deleteColumn()}
          className="buttonTipTap"
        >
          Eliminar columna
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addRowBefore().run()}
          disabled={!editor.can().addRowBefore()}
          className="buttonTipTap"
        >
          Agregar fila antes
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addRowAfter().run()}
          disabled={!editor.can().addRowAfter()}
          className="buttonTipTap"
        >
          Agregar fila después
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteRow().run()}
          disabled={!editor.can().deleteRow()}
          className="buttonTipTap"
        >
          Eliminar fila
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteTable().run()}
          disabled={!editor.can().deleteTable()}
          className="buttonTipTap"
        >
          Eliminar tabla
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().mergeCells().run()}
          disabled={!editor.can().mergeCells()}
          className="buttonTipTap"
        >
          Unir celdas
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().splitCell().run()}
          disabled={!editor.can().splitCell()}
          className="buttonTipTap"
        >
          Dividir celda
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
          disabled={!editor.can().toggleHeaderColumn()}
          className="buttonTipTap"
        >
          Activar/desactivar columna de encabezado
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeaderRow().run()}
          disabled={!editor.can().toggleHeaderRow()}
          className="buttonTipTap"
        >
          Activar/desactivar fila de encabezado
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeaderCell().run()}
          disabled={!editor.can().toggleHeaderCell()}
          className="buttonTipTap"
        >
          Activar/desactivar celda de encabezado
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().mergeOrSplit().run()}
          className="buttonTipTap"
        >
          Unir o dividir
        </button>
        <button
          type="button"
          onClick={() =>
            editor.chain().focus().setCellAttribute("colspan", 2).run()
          }
          disabled={!editor.can().setCellAttribute("colspan")}
          className="buttonTipTap"
        >
          Establecer atributo de celda
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().fixTables().run()}
          className="buttonTipTap"
          disabled={!editor.can().fixTables()}
        >
          Arreglar tablas
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().goToNextCell().run()}
          className="buttonTipTap"
          disabled={!editor.can().goToNextCell()}
        >
          Ir a la siguiente celda
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().goToPreviousCell().run()}
          className="buttonTipTap"
          disabled={!editor.can().goToPreviousCell()}
        >
          Ir a la celda anterior
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={`buttonTipTap ${editor.isActive("bold") && "is-active"}`}
        >
          Negrita
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={`buttonTipTap ${
            editor.isActive("italic") ? "is-active" : ""
          }`}
        >
          Cursiva
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={`buttonTipTap ${
            editor.isActive("strike") ? "is-active" : ""
          }`}
        >
          Tachado
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={`buttonTipTap ${
            editor.isActive("code") ? "is-active" : ""
          }`}
        >
          Código
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className="buttonTipTap"
        >
          Borrar marcas
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().clearNodes().run()}
          className="buttonTipTap"
        >
          Borrar nodos
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={`buttonTipTap ${
            editor.isActive("paragraph") ? "is-active" : ""
          }`}
        >
          Párrafo
        </button>
        <button
          type="button"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={`buttonTipTap ${
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }`}
        >
          H1
        </button>
        <button
          type="button"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={`buttonTipTap ${
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }`}
        >
          H2
        </button>
        <button
          type="button"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={`buttonTipTap ${
            editor.isActive("heading", { level: 3 }) ? "is-active" : ""
          }`}
        >
          H3
        </button>
        <button
          type="button"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={`buttonTipTap ${
            editor.isActive("heading", { level: 4 }) ? "is-active" : ""
          }`}
        >
          H4
        </button>
        <button
          type="button"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          className={`buttonTipTap ${
            editor.isActive("heading", { level: 5 }) ? "is-active" : ""
          }`}
        >
          H5
        </button>
        <button
          type="button"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          className={`buttonTipTap ${
            editor.isActive("heading", { level: 6 }) ? "is-active" : ""
          }`}
        >
          H6
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`buttonTipTap ${
            editor.isActive("bulletList") ? "is-active" : ""
          }`}
        >
          Lista con viñetas
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`buttonTipTap ${
            editor.isActive("orderedList") ? "is-active" : ""
          }`}
        >
          Lista ordenada
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={`buttonTipTap ${
            editor.isActive("codeBlock") ? "is-active" : ""
          }`}
        >
          Bloque de código
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`buttonTipTap ${
            editor.isActive("blockquote") ? "is-active" : ""
          }`}
        >
          Cita en bloque
        </button>

        <button
          type="button"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          className="buttonTipTap"
        >
          Regla horizontal
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setHardBreak().run()}
          className="buttonTipTap"
        >
          Salto de línea
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className="buttonTipTap"
        >
          Deshacer
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className="buttonTipTap"
        >
          Rehacer
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setColor("#958DF1").run()}
          className={` buttonTipTap
${editor.isActive("textStyle", { color: "#958DF1" }) ? "is-active" : ""}`}
        >
          Color de texto
        </button>
      </div>
    </div>
  );
};

export const extensions = [
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  Highlight.configure({
    multicolor: true, // Permite múltiples colores
  }),
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Link.configure({
    openOnClick: true,
    autolink: true,
    defaultProtocol: "https",
    protocols: ["http", "https"],
    isAllowedUri: (url, ctx) => {
      try {
        // construct URL
        const parsedUrl = url.includes(":")
          ? new URL(url)
          : new URL(`${ctx.defaultProtocol}://${url}`);

        // use default validation
        if (!ctx.defaultValidate(parsedUrl.href)) {
          return false;
        }

        // disallowed protocols
        const disallowedProtocols = ["ftp", "file", "mailto"];
        const protocol = parsedUrl.protocol.replace(":", "");

        if (disallowedProtocols.includes(protocol)) {
          return false;
        }

        // only allow protocols specified in ctx.protocols
        const allowedProtocols = ctx.protocols.map((p) =>
          typeof p === "string" ? p : p.scheme
        );

        if (!allowedProtocols.includes(protocol)) {
          return false;
        }

        // disallowed domains
        const disallowedDomains = [
          "example-phishing.com",
          "malicious-site.net",
        ];
        const domain = parsedUrl.hostname;

        if (disallowedDomains.includes(domain)) {
          return false;
        }

        // all checks have passed
        return true;
      } catch {
        return false;
      }
    },
    shouldAutoLink: (url) => {
      try {
        // construct URL
        const parsedUrl = url.includes(":")
          ? new URL(url)
          : new URL(`https://${url}`);

        // only auto-link if the domain is not in the disallowed list
        const disallowedDomains = [
          "example-no-autolink.com",
          "another-no-autolink.com",
        ];
        const domain = parsedUrl.hostname;

        return !disallowedDomains.includes(domain);
      } catch {
        return false;
      }
    },
  }),
];

const TipTap = ({ modeEdit = false, item, closeModalEdit, listTyc }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [content, setContent] = useState("");
  const [editorInstance, setEditorInstance] = useState(null); // Guardar la instancia del editor
  const [isLoading, setIsLoading] = useState(false);

  const OnSubmit = async (datos) => {
    if (!editorInstance) {
      toast.error("El editor aún no está listo. Intenta de nuevo.");
      return;
    }

    try {
      setIsLoading(true);
      if (modeEdit) {
        await updateTyc(item.id, {
          titulo: datos.titulo,
          texto: editorInstance.getHTML(),
        });
        listTyc();

        toast.success("Datos actualizados");
        closeModalEdit();
      } else {
        await createTyc({
          titulo: datos.titulo,
          texto: editorInstance.getHTML(),
        });
        toast.success("Términos y condiciones agregados correctamente");

        reset();
        editorInstance.commands.clearContent();
        listTyc();
      }
    } catch (error) {
      console.error("Error al guardar:", error.response?.data || error.message);
      toast.error(error.response?.data || "Error desconocido");
      toast.error("Error al guardar términos y condiciones");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (modeEdit && item && editorInstance) {
      setValue("titulo", item.titulo);
      setContent(item.texto);
      editorInstance.commands.setContent(item.texto);
      editorInstance.chain().focus().run();
    }
  }, [modeEdit, item, setValue, editorInstance]);

  return (
    <section className={`${!modeEdit && "container-tipTap"}`}>
      <form onSubmit={handleSubmit(OnSubmit)}>
        <div className="container-input-tiptap">
          <label htmlFor="titulo">Título:</label>
          <input
            name="titulo"
            placeholder="Escribe un título"
            className="input-tiptap"
            {...register("titulo", {
              required: "El campo título es obligatorio",
            })}
          />
          {errors.titulo && <p className="error">{errors.titulo.message}</p>}
        </div>
        <div className="custom-editor-container">
          <EditorProvider
            slotBefore={<MenuBar editor={editorInstance} />}
            extensions={extensions}
            content={content}
            onUpdate={({ editor }) => {
              setContent(editor.getHTML());
              setEditorInstance(editor);
            }}
            onCreate={({ editor }) => setEditorInstance(editor)} // Capturar la instancia del editor
          />
        </div>
        <button
          disabled={isLoading}
          type="submit"
          className={`btn-admin-defect ${isLoading && "isLoading"}`}
        >
          {isLoading ? (
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          ) : (
            <>{modeEdit ? "Actualizar" : "Agregar términos y condiciones"}</>
          )}
        </button>
      </form>
    </section>
  );
};

export default TipTap;
