import React, { useEffect, useState } from "react";
import TableTyc from "./TableTyc";
import TipTap from "components/editor-tiptap/TipTap";
import { getAllTyc } from "services/tyc.service";

const ManageContentTyC = () => {
  const [items, setItems] = useState(null);

  const listTyc = async () => {
    try {
      const response = await getAllTyc();
      setItems(response.data);
    } catch (error) {
      console.error("No se pudo obtener los términos y condiciones", error);
    }
  };

  useEffect(() => {
    listTyc();
  }, []);

  return (
    <>
      <h1 className="centrar title__tyc mb-3">Términos y condiciones</h1>
      <section className="main__content">
        <article>
          <TipTap listTyc={listTyc} />
        </article>
        <article className="first-item">
          <TableTyc items={items} listTyc={listTyc} />
        </article>
      </section>
    </>
  );
};

export default ManageContentTyC;
