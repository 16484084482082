import React, { useEffect, useState } from "react";
import Slider from "./video-slider/Slider";
import Slider3D from "./video-slider/Slider3D";
import GridHotels from "./Hotels/GridHotels";
import Testimonials from "common/Testimonials";
import ResponsiveImage from "common/ResponsiveImage";
import { getBannerGac } from "services/banners";
// import MembershipCard from "./membership/MembershipCard";
import "styles/Home.css";
import { saveClickPromo } from "services/analitycs-black-friday";
import { AnimatedSection, ScrollAnimations } from "hooks/useScrollAnimations";
import Skeleton from "./skeleton/Skeleton";

const Home = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getBanners = async () => {
      try {
        const response = await getBannerGac();
        const sortedBanners = response.data.sort((a, b) => a.order - b.order);
        setBanners(sortedBanners);
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };

    getBanners();
  }, []);

  const handleMetrics = async (promocion = "", link = "") => {
    try {
      window.open(link.link, "_blank");
      await saveClickPromo({
        promocion,
        tipo: promocion,
        año: new Date().getFullYear().toString(),
        mes: (new Date().getMonth() + 1).toString(),
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ScrollAnimations>
      <AnimatedSection className="slide-up">
        <section className="add-margin">
          <Slider
            autoSlideInterval={20}
            showIndicators={true}
            showButtons={true}
          >
            {loading
              ? Array.from({ length: 1 }).map((_, index) => (
                  <div className="slide-content" key={index}>
                    <Skeleton type="image" width="100%" height="810px" />
                  </div>
                ))
              : banners.map(
                  ({
                    name,
                    id,
                    img_mobile,
                    img_tablet,
                    img_pc,
                    img_large,
                    link,
                  }) => (
                    <button
                      onClick={() =>
                        handleMetrics(`Click en el banner ${name}`, { link })
                      }
                      className="slide-content"
                      key={id}
                    >
                      <ResponsiveImage
                        mobil={img_mobile}
                        tablet={img_tablet}
                        pc={img_pc}
                        large={img_large}
                      />
                    </button>
                  )
                )}
          </Slider>
        </section>
      </AnimatedSection>
      <Slider3D />
      {/* <MembershipCard /> */}
      <AnimatedSection className="fade-in">
        <GridHotels />
      </AnimatedSection>

      <AnimatedSection className="slide-left">
        <Testimonials />
      </AnimatedSection>
    </ScrollAnimations>
  );
};

export default Home;
