import React, { useState } from "react";
import CustomModal from "common/CustomModal";
import { useModalGac } from "hooks/useModalGac";
import toast from "react-hot-toast";
import InstagramVideo from "common/InstagramVideo";
import { ReactComponent as CloseIcon } from "assets/icons/components/search/close.svg";
import { ReactComponent as IconDelete } from "assets/icons/components/admin/delete.svg";
import { ReactComponent as IconEdit } from "assets/icons/components/admin/edit-button.svg";

const Table = ({ columns, data, onEdit, onDelete, Component }) => {
  const [isOpenModal, openModal, closeModal] = useModalGac(false);
  const [isOpenModalEdit, openModalEdit, closeModalEdit] = useModalGac(false);
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const renderCell = (row, column) => {
    const value = row[column.key];

    if (column.isImage && value) {
      return (
        <img
          src={value}
          alt={column.label}
          style={{
            width: "70px",
            height: "auto",
            padding: "10px",
            background: "var(--azul-gac)",
          }}
          className="img-table"
          loading="lazy"
        />
      );
    } else if (column.isVideoInstagran) {
      return <InstagramVideo embedCode={value} />;
    }

    return value;
  };

  const handleActions = async (opt = "", item) => {
    switch (opt) {
      case "Edit":
        setContent(
          <Component
            modeEdit={true}
            itemSelect={item}
            closeModalEdit={closeModalEdit}
          />
        );
        openModalEdit();
        break;
      case "Delete":
        setContent(
          <article className="card-delete text-center">
            <CloseIcon className="icon-modal-close" onClick={closeModal} />
            <h5>Eliminar item</h5>
            <p>¿Estás seguro de que deseas eliminar este elemento?</p>
            <button
              className="btn-action"
              onClick={() => handleDelete(item.id)}
            >
              {isLoading ? (
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                ></div>
              ) : (
                "Si, eliminar"
              )}
            </button>
            <button className="btn-action cancel" onClick={closeModal}>
              No, Cancelar
            </button>
          </article>
        );
        openModal();
        break;

      default:
        break;
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await onDelete(id);
      toast.success("Elemento eliminado correctamente");
      closeModal();
    } catch (error) {
      toast.error("Ocurrio un error");
      console.error("No se pudo eliminar el item", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <table className="custom-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{column.label}</th>
            ))}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {columns.map((column) => (
                <td key={column.key}>{renderCell(row, column)} </td>
              ))}
              <td>
                <button
                  className="btn-options-table edit"
                  onClick={() => handleActions("Edit", row)}
                >
                  <IconEdit />
                </button>
                <button
                  className="btn-options-table"
                  onClick={() => handleActions("Delete", row)}
                >
                  <IconDelete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <CustomModal
        className={`modalGac ${isOpenModal && "is-open"}`}
        isOpen={isOpenModal}
        closeModal={closeModal}
      >
        {content}
      </CustomModal>

      <CustomModal
        className={`modalGac ${isOpenModalEdit && "is-open"}`}
        isOpen={isOpenModalEdit}
        closeModal={closeModalEdit}
      >
        <article className="card-delete">
          <CloseIcon className="icon-modal-close" onClick={closeModalEdit} />
          {content}
        </article>
      </CustomModal>
    </>
  );
};

export default Table;
