import React, { useState, useEffect } from "react";
import { ReactComponent as IconPlus } from "assets/icons/mas.svg";
import { ReactComponent as IconLess } from "assets/icons/menos.svg";
import { getAllTyc } from "services/tyc.service";
import Skeleton from "components/skeleton/Skeleton";
import "styles/terminos/TermsAndConditions.css";

const TermsAndConditions = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [tyc, setTyc] = useState(null);

  const handleQuestionClick = (question) => {
    setActiveQuestion(question === activeQuestion ? null : question);
  };

  useEffect(() => {
    const listTyc = async () => {
      try {
        const response = await getAllTyc();
        setTyc(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    listTyc();
  }, []);

  return (
    <section className="containerTyC">
      <h2 className="title__tyc text-center mb-5">
        Sección términos y condiciones promocionales
      </h2>

      {tyc !== null ? (
        <article className="container-__accordion">
          {tyc?.map((item) => (
            <div className="acoordionTyc" key={item.id}>
              <div
                className="accordion__headerTyc"
                onClick={() => handleQuestionClick(item.titulo)}
              >
                {item.titulo}
                {activeQuestion === item.titulo ? (
                  <IconLess className="icon-tyc" />
                ) : (
                  <IconPlus className="icon-tyc" />
                )}
              </div>
              <div
                className={`accordion__tyc-content ${
                  activeQuestion === item.titulo && "open"
                }`}
              >
                <span
                  className="accordion__tyc-text"
                  dangerouslySetInnerHTML={{ __html: item.texto }}
                ></span>
              </div>
            </div>
          ))}
        </article>
      ) : (
        <>
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              className="acoordionTyc"
              key={index}
              style={{ margin: "2em 0" }}
            >
              <Skeleton type="image" width="100%" height="50px" />
            </div>
          ))}
        </>
      )}
    </section>
  );
};

export default TermsAndConditions;
