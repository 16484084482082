import React, { useEffect, useState } from "react";
import Loaging from "common/Loaging";
import { NavLink } from "react-router-dom";
import Table from "../components/common/Tablet";
import { getTestimonials, deleteTestimony } from "services/testimonials";
import TestimonialsForm from "./TestimonialsForm";

const columns = [
  { key: "id", label: "#" },
  { key: "img", label: "Imagen", isImage: true },
  { key: "username", label: "Usuario" },
  { key: "comentario", label: "Comentario" },
];

export const PageTestimonials = () => {
  const [testimonials, setTestimonials] = useState(null);

  const listTestimonials = async () => {
    try {
      const { data } = await getTestimonials();
      setTestimonials(data);
    } catch (error) {
      console.log(error);
      alert("No se pudo obtener el listado de setTestimonials");
    }
  };

  const handleDeleteTestimony = async (id) => {
    try {
      await deleteTestimony(id);
      await listTestimonials();
    } catch (error) {
      console.log("error handleDeleteVideo", error);
    }
  };

  useEffect(() => {
    listTestimonials();
  }, []);

  return (
    <article>
      <h1 className="centrar">Listado de testimonios</h1>
      <NavLink
        className="btn-about-contact btn-add-banner"
        to="/d77cdf4f4ab48f705fbed94d26c7ee03831add4b9cbe2a4c7c578f8cc7361215/create-testimony"
      >
        Agregar testimonio
      </NavLink>

      {!testimonials ? (
        <Loaging />
      ) : (
        <Table
          columns={columns}
          data={testimonials}
          onEdit={() => console.log("ACTION")}
          onDelete={handleDeleteTestimony}
          Component={TestimonialsForm}
        />
      )}
    </article>
  );
};
