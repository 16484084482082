import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { Link } from "@tiptap/extension-link";
import { Bold } from "@tiptap/extension-bold";
import { Underline } from "@tiptap/extension-underline";
import { Highlight } from "@tiptap/extension-highlight";
import ListItem from "@tiptap/extension-list-item";

const TiptapEditor = ({ value, onChange, placeholder }) => {
  // Inicializa el editor con las extensiones necesarias
  const editor = useEditor({
    extensions: [StarterKit, Link, Bold, Underline, Highlight, ListItem],
    content: value, // Establece el contenido inicial
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML()); // Llama a onChange cada vez que el contenido cambie
    },
  });

  return (
    <div className="custom-editor-container">
      <div className="control-group">
        <div className="button-group">
          {/* Botones de formato */}
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className="buttonTipTap"
          >
            Negrita
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className="buttonTipTap"
          >
            Subrayar
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            className="buttonTipTap"
          >
            Resaltar
          </button>
          <button
            onClick={() =>
              editor
                .chain()
                .focus()
                .setLink({ href: "https://example.com" })
                .run()
            }
            className="buttonTipTap"
          >
            Enlace
          </button>
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className="buttonTipTap"
          >
            Lista
          </button>
        </div>
      </div>
      <EditorContent editor={editor} />
    </div>
  );
};

export default TiptapEditor;
