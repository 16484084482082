import React from "react";
import "../../styles/skeleton/index.css";

const Skeleton = ({
  type = "text",
  width = "100%",
  height = "1rem",
  className = "",
}) => {
  return (
    <div
      className={`skeleton ${type} ${className}`}
      style={{ width, height }}
    ></div>
  );
};

export default Skeleton;
