import { apiConfig } from "./apiConfig";

const getAllTyc = async () => {
  return await apiConfig.get("/tyc");
};
const createTyc = async (payload) => {
  return await apiConfig.post("/tyc", payload);
};
const deleteTyc = async (id) => {
  return await apiConfig.delete(`tyc/delete/${id}`);
};
const updateTyc = async (id, payload) => {
  return await apiConfig.patch(`tyc/update/${id}`, payload);
};

export { getAllTyc, createTyc, deleteTyc, updateTyc };
