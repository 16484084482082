import React, { useState } from "react";
import toast from "react-hot-toast";
import { uploadFileGac } from "services/FileManager";
import { SendEmail } from "services/UploadFile";
import "styles/pqrs.css";
const tipoPQRS = [
  {
    label: "Petición",
    key: "Petición",
  },
  {
    label: "Queja",
    key: "Queja",
  },
  {
    label: "Reclamo",
    key: "Reclamo",
  },
  {
    label: "Sugerencia",
    key: "Sugerencia",
  },
  {
    label: "Felicitaciones",
    key: "Felicitaciones",
  },
];

const IdType = [
  {
    label: "Cédula de ciudadania",
    key: "Cédula de ciudadania",
  },
  {
    label: "Cédula de extranjeria",
    key: "Cédula de extranjeria",
  },
  {
    label: "NIT",
    key: "NIT",
  },
  {
    label: "Pasaporte",
    key: "Pasaporte",
  },
  {
    label: "Tarjeta de Identidad",
    key: "Tarjeta de Identidad",
  },
  {
    label: "NUIP",
    key: "NUIP",
  },
  {
    label: "Carnet diplomatico",
    key: "Carnet diplomatico",
  },
  {
    label: "Registro de informacion fiscal Venezolano",
    key: "Registro de informacion fiscal Venezolano",
  },
  {
    label: "Permiso Especial de Permanencia",
    key: "Permiso Especial de Permanencia",
  },
  {
    label: "NIT extranjero",
    key: "NIT extranjero",
  },
];

const dataPqrs = {
  tipo: "",
  contrato: "",
  tipoid: "",
  numid: "",
  nombres: "",
  apellidos: "",
  telefono: "",
  correo: "",
  direccion: "",
  ciudad: "",
  pqrs: "",
  adjunto: "",
};
const PQRS = () => {
  const [form, setForm] = useState(dataPqrs);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    // pdf, .doc, .docx, image
    if (files) {
      const file = files[0];
      const maxSize = 10 * 1024 * 1024; // Tamaño máximo en bytes (10 MB)
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (file?.size > maxSize) {
        toast.error(
          "El archivo es demasiado grande. El tamaño máximo permitido es de 10 mb."
        );
        e.target.value = null;
        return;
      }

      if (!allowedFileTypes.includes(file?.type)) {
        toast.error(
          "El tipo de archivo seleccionado no está permitido. Por favor, selecciona una imagen, PDF o documento válido."
        );
        e.target.value = null;
        return;
      }

      setForm((prevState) => ({
        ...prevState,
        adjunto: files[0],
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const UploadFile = async () => {
    const formData = new FormData();
    formData.append("fileToUpload", form.adjunto);
    formData.append("uploadDir", "PQRS_GAC");
    const response = await uploadFileGac(formData);
    setForm((prevState) => ({
      ...prevState,
      adjunto: response.data.data.fileUrl,
    }));
    return response;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await UploadFile();
      console.log("response____", response);

      if (response.status === 200) {
        const {
          tipo,
          contrato,
          tipoid,
          numid,
          nombres,
          apellidos,
          telefono,
          correo,
          direccion,
          ciudad,
          pqrs,
        } = form;

        const payload = {
          tipo,
          contrato,
          tipoid,
          numid,
          nombres,
          apellidos,
          telefono,
          correo,
          direccion,
          ciudad,
          pqrs,
          adjunto: response.data.data.fileUrl,
        };
        await SendEmail(payload);
        setForm(dataPqrs);
        toast.success("Se envio la PQRS correctamente");
      }
    } catch (error) {
      toast.error("Algo salio mal, intentelo de nuevo");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="centrar">
      <div className="container-contact">
        <div className="row">
          <div className="contenedor-form">
            <img
              loading="lazy"
              src="/images/logo/logo.webp"
              alt="Grupo Alianza Colombia Viajar"
              width="200"
              className="logo1 m-1"
            ></img>
            <h3 className="title-policy fw-bold text-center">
              NUEVO RADICADO DE PQRS
            </h3>
            <div className="login-box">
              <form autoComplete="new-password" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <select
                        className="custom-select"
                        required
                        name="tipo"
                        value={form.tipo}
                        onChange={handleChange}
                        disabled={loading}
                      >
                        <option value="">Tipo de solicitud *</option>
                        {tipoPQRS.map(({ label, key }) => (
                          <option value={key} key={key}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <input
                        required
                        name="contrato"
                        value={form.contrato}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                      <label>Contrato *</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <select
                        className="custom-select"
                        required
                        name="tipoid"
                        value={form.tipoid}
                        onChange={handleChange}
                        disabled={loading}
                      >
                        <option value="">Tipo de documento *</option>
                        {IdType.map(({ label, key }) => (
                          <option value={key} key={key}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <input
                        required
                        name="numid"
                        value={form.numid}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                      <label>Número de identificación *</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <input
                        required
                        name="nombres"
                        value={form.nombres}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                      <label>Nombres *</label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <input
                        required
                        name="apellidos"
                        value={form.apellidos}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                      <label>Apellidos *</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <input
                        required
                        name="telefono"
                        value={form.telefono}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                      <label>Teléfono *</label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <input
                        required
                        name="correo"
                        value={form.correo}
                        onChange={handleChange}
                        type="email"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                      <label>Correo electrónico *</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <input
                        required
                        name="direccion"
                        value={form.direccion}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                      <label>Dirección *</label>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="user-box">
                      <input
                        required
                        name="ciudad"
                        value={form.ciudad}
                        onChange={handleChange}
                        type="text"
                        autoComplete="new-password"
                        disabled={loading}
                      />
                      <label>Ciudad *</label>
                    </div>
                  </div>
                </div>
                <div className="user-box">
                  <textarea
                    required
                    name="pqrs"
                    value={form.pqrs}
                    onChange={handleChange}
                    type="text"
                    autoComplete="new-password"
                    className="textArea"
                    placeholder="Describa el motivo de su solicitud o PQR *"
                    disabled={loading}
                  ></textarea>
                </div>
                {form.adjunto !== "" ? (
                  ""
                ) : (
                  <div className="alert alert-danger mt-1" role="alert">
                    Seleccione un archivo - Maximo (10 MB)
                  </div>
                )}
                <label className="custum-file-upload" htmlFor="file">
                  <div className="icon_upload">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        fillRule="evenodd"
                        d="M10 1a1 1 0 00-.707.293l-6 6A1 1 0 003 8v12a3 3 0 003 3h1a1 1 0 100-2H6a1 1 0 01-1-1V9h5a1 1 0 001-1V3h7a1 1 0 011 1v5a1 1 0 102 0V4a3 3 0 00-3-3h-8zM9 7H6.414L9 4.414V7zm5 8.5a2.5 2.5 0 015 0V17h1a2 2 0 110 4h-7a2 2 0 110-4h1v-1.5zm2.5-4.5a4.5 4.5 0 00-4.484 4.122A4.002 4.002 0 0013 23h7a4 4 0 00.984-7.878A4.5 4.5 0 0016.5 11z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <div className="text">
                    <span>Clic para subir archivo</span>
                  </div>
                  <input
                    type="file"
                    id="file"
                    accept=".pdf, .doc, .docx, image/*"
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </label>
                <div className="alert alert-secondary" role="alert">
                  {form.adjunto.name} {form.adjunto.type}
                </div>
                <p htmlFor="termino" className="terminos">
                  <input id="termino" type="checkbox" required />
                  Con el diligenciamiento de mis datos en el presente formulario
                  acepto de forma previa, expresa e informada la Autorización
                  para el tratamiento de datos personales.{" "}
                  <a href="/files/Politica-de-tratamiento-de-datos-Personales.pdf">
                    Ver tratamiento de datos personales
                  </a>
                </p>
                <button
                  className="btn-login"
                  style={{ width: "100%", padding: ".6em" }}
                  disabled={loading}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  ) : (
                    "Enviar"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PQRS;
