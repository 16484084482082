import React from "react";
import ChannelsAttention from "components/contact/ChannelsAttention";
import { NavLink } from "react-router-dom";
import ImageAccount from "assets/images/questions/Estado_de_mi_cuenta.png";
import "styles/footer.css";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <section className="centrar">
        <img
          src="/images/Banner_footer_GAC.jpg"
          alt="Grupo alianza colombia informa: No tenemos servicios tercerizados"
          className="img-fluid"
        />
      </section>
      <footer className="section-footer row">
        <span className="border-top-footer"></span>
        <div className="col-md-6 col-lg-4 col-xl-4 mx-auto ">
          <h4 className=" fw-bold centrar title-article">Legales</h4>
          <div className="mt-5 container-item-footer">
            <NavLink to="/pqrs">PQRS</NavLink>
            <br></br>

            <NavLink to="/sagrilaft">SAGRILAFT Y PTEE</NavLink>
            <br></br>
            <NavLink to="/terminos-y-condiciones">
              Términos y condiciones
            </NavLink>
            <br></br>

            <NavLink to="/linea-etica">Línea ética</NavLink>
            <br></br>

            <a
              href="/files/tratamiento-de-datos-Personales.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de tratamiento de datos personales
            </a>
            <br></br>
            <a
              href="/files/Aviso_de_privacidad.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aviso de privacidad
            </a>
            <br></br>
            <NavLink to="/politica-de-ley-de-retracto">
              Política ley de retracto
            </NavLink>
            <br></br>
            <NavLink to="/politica-de-proteccion-de-menores">
              Política de protección a menores
            </NavLink>
            <br></br>
            <NavLink to="/bio-seguridad">Bio-seguridad</NavLink>
            <br></br>
            <a
              href="https://sedeelectronica.sic.gov.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              SuperIntendecia de Industria
            </a>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4 mx-auto mb-4 fading-vertical-border">
          <h4 className="fw-bold mb-2 text-center title-article">
            Medios de pago
          </h4>
          <br></br>
          <NavLink to="/pagos" className="centrar btn-scale">
            <img
              loading="lazy"
              src={ImageAccount}
              alt="mercado pago"
              width={180}
              height={50}
            />
          </NavLink>
          <div className="centrar">
            <a
              href="https://portalpagos.davivienda.com/#/comercio/6857/GRUPO%20ALIANZA%20COLOMBIA%20SAS"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-davivienda"
            >
              PSE en{" "}
              <img
                loading="lazy"
                src="/images/logo/davivienda.webp"
                alt="pse davivienda"
                width={50}
              />
            </a>
          </div>
          <br></br>

          <NavLink to="/pagos" className="centrar">
            <img
              loading="lazy"
              src="/images/logo/logo-mercado-pago.svg"
              alt="mercado pago"
              width={180}
              height={50}
            />
          </NavLink>
          <br></br>

          <div className="centrar">
            <button
              className="btn-bancolombia"
              data-bs-toggle="modal"
              data-bs-target="#Modal_QR_Bancolombia"
            >
              <img
                loading="lazy"
                src="/images/logo/bancolombia.svg"
                width={200}
                height={50}
                alt="Pagar con Codigo QR de Bancolombia"
              />
            </button>
            <div
              className="modal fade"
              id="Modal_QR_Bancolombia"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-md">
                <div className="modal-content">
                  <div className="modal-body">
                    <img
                      loading="lazy"
                      src="/files/QR.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 col-lg-4 col-xl-4 mx-auto mb-4 fading-vertical-border centrar">
          <ChannelsAttention isFooter={true} />
        </div>
        <span className="border-bottom-footer"></span>

        <div
          className="row"
          style={{
            margin: "0 auto",
          }}
        >
          <div className="col-sm-12 col-md-4 col-lg-1 col-xl-2 mt-4">
            <img
              loading="lazy"
              src="/images/logo/logo.webp"
              alt="logo"
              width={200}
              className="centrar"
            />
          </div>
          <div className="col-sm-12 col-md-8 col-lg-10 col-xl-10">
            <div className="text-center mt-4">
              <p>
                RNT N° 127027 Grupo Alianza Colombia SAS. NIT. 901088947-6,
                Oficina Principal Bogotá, Calle 93B # 17-25 Oficina 309-310-311
                Edificio Centro Empresarial PH. Estamos comprometidos con: Ley
                679 de 2001 y ley 1336 de 2009. Contra la explotación, la
                pornografía, el turismo sexual y otras formas de abuso a los
                menores. Decreto 2737 de 1989. Por el cual se expide el código
                del menor y en la cual está en contra de la explotación laboral
                infantil. Ley 397 de 1997. Contra el tráfico de Patrimonio
                Cultural. Ley 599 de 2000, decreto 1608 de 1978 y Ley 1453 de
                2011. Contra el tráfico de especies como flora y fauna
                silvestre. Ley 1335 de 2009. Antitabaco, por un espacio libre de
                humo del tabaco. Ley 1482 de 2011. Garantizar la protección de
                los derechos de una persona, grupo de personas, comunidad o
                pueblo, que son vulnerados a tráficos de actos de racismo o
                discriminación. Damos cumplimiento a lo establecido por la Ley
                1581 de 2012, sobre protección de datos personales.
              </p>

              <h6 className="mt-2">
                <strong>
                  © {year} | Todos los derechos reservados. Grupo Alianza
                  Colombia.SAS
                </strong>
              </h6>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
