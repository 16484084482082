import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { saveBanner } from "services/banners";
import { updateBanner } from "services/banners";
import toast from "react-hot-toast";
import { uploadFileGac } from "services/FileManager";
import { ReactComponent as IconUploadFile } from "assets/icons/uploadFile.svg";
import "styles/dashboardAdmin/DashboardBanners.css";

const CreateBanner = ({
  modeEdit = false,
  bannerSelect = {},
  listBanners,
  closeModalEdit,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (modeEdit) {
      setValue("img_mobile", bannerSelect.img_mobile);
      setValue("img_tablet", bannerSelect.img_tablet);
      setValue("img_pc", bannerSelect.img_pc);
      setValue("img_large", bannerSelect.img_large);
      setValue("link", bannerSelect.link);
      setValue("name", bannerSelect.name);
      setValue("order", parseInt(bannerSelect.order));
    }
  }, [modeEdit, setValue, bannerSelect]);

  const prevImageMobile = watch("img_mobile");
  const prevImageTablet = watch("img_tablet");
  const prevImagePC = watch("img_pc");
  const prevImageLarge = watch("img_large");

  const onSubmit = handleSubmit(async (data) => {
    try {
      const urls = {};

      // Verifica si el valor es un archivo nuevo o una URL existente
      for (const key of Object.keys(data)) {
        if (data[key] && typeof data[key] === "object" && data[key][0]) {
          // Si es un archivo (nuevo), debemos subirlo
          if (data[key][0].name && !data[key][0].name.includes("https")) {
            const formData = new FormData();
            formData.append("fileToUpload", data[key][0]); // Subir nueva imagen
            const response = await uploadFileGac(formData);
            urls[key] = response.data.data.fileUrl; // Guarda la nueva URL
          }
        } else {
          // Si no es un archivo o es un campo con una URL existente, mantenemos el valor
          urls[key] = bannerSelect[key] || data[key]; // Usa el valor existente o el link que viene de `bannerSelect`
        }
      }

      urls.link = data.link;
      urls.order = parseInt(data.order);
      urls.name = data.name;

      if (modeEdit) {
        // Actualizar banner existente si estamos en modo edición
        await updateBanner(bannerSelect.id, urls);
        listBanners();
        closeModalEdit();
        toast.success("Banner actualizado correctamente");
      } else {
        // Guardar un nuevo banner si no estamos en modo edición
        await saveBanner(urls);
        toast.success("Banner creado correctamente");
      }
    } catch (error) {
      console.error("Error al subir las imágenes:", error);
      toast.error("Error al subir las imágenes");
    } finally {
      reset(); // Reiniciar el formulario
    }
  });

  return (
    <div className={modeEdit ? "" : "container-upload-banners"}>
      <h2 className="upload-title">{modeEdit ? "Editar" : "Crear"} Banner</h2>
      <form onSubmit={onSubmit}>
        <section className="upload-grid">
          <div className="upload-container">
            <label className="upload-label">
              <IconUploadFile className="upload-icon" />
              <span className="upload-text">
                Subir imagen para dispositivo mobil{" "}
                <strong className="text-danger">
                  (Ancho: 720px Alto: 1280)
                </strong>
              </span>
              <input
                type="file"
                accept="image/*"
                {...register("img_mobile", {
                  required: !modeEdit && "El campo Imagen Mobil es obligatorio",
                })}
                className="hidden-input"
              />
              {errors.img_mobile && (
                <span className="text-danger">{errors.img_mobile.message}</span>
              )}
            </label>
            {prevImageMobile instanceof FileList &&
            prevImageMobile.length > 0 ? (
              <img
                src={URL.createObjectURL(prevImageMobile[0])}
                alt="imagen mobil banner"
                className="upload-image"
              />
            ) : (
              modeEdit && (
                <img
                  src={bannerSelect.img_mobile}
                  alt="imagen mobil banner"
                  className="upload-image"
                />
              )
            )}
          </div>
          <div className="upload-container">
            <label className="upload-label">
              <IconUploadFile className="upload-icon" />
              <span className="upload-text">
                Subir imagen para Tablet{" "}
                <strong className="text-danger">
                  (Ancho: 1280px Alto: 720)
                </strong>
              </span>
              <input
                type="file"
                accept="image/*"
                {...register("img_tablet", {
                  required:
                    !modeEdit && "El campo Imagen Tablet es obligatorio",
                })}
                className="hidden-input"
              />
              {errors.img_tablet && (
                <span className="text-danger">{errors.img_tablet.message}</span>
              )}
            </label>
            {prevImageTablet instanceof FileList &&
            prevImageTablet.length > 0 ? (
              <img
                src={URL.createObjectURL(prevImageTablet[0])}
                alt="imagen tablet banner"
                className="upload-image"
              />
            ) : (
              modeEdit && (
                <img
                  src={bannerSelect.img_tablet}
                  alt="imagen tablet banner"
                  className="upload-image"
                />
              )
            )}
          </div>
          <div className="upload-container">
            <label className="upload-label">
              <IconUploadFile className="upload-icon" />
              <span className="upload-text">
                Subir imagen para PC{" "}
                <strong className="text-danger">
                  (Ancho: 1920 Alto: 1080)
                </strong>
              </span>
              <input
                type="file"
                accept="image/*"
                {...register("img_pc", {
                  required: !modeEdit && "El campo Imagen PC es obligatorio",
                })}
                className="hidden-input"
              />
              {errors.img_pc && (
                <span className="text-danger">{errors.img_pc.message}</span>
              )}
            </label>
            {prevImagePC instanceof FileList && prevImagePC.length > 0 ? (
              <img
                src={URL.createObjectURL(prevImagePC[0])}
                alt="imagen pc banner"
                className="upload-image"
              />
            ) : (
              modeEdit && (
                <img
                  src={bannerSelect.img_pc}
                  alt="imagen pc banner"
                  className="upload-image"
                />
              )
            )}
          </div>
          <div className="upload-container">
            <label className="upload-label">
              <IconUploadFile className="upload-icon" />
              <span className="upload-text">
                Subir imagen para pantallas grandes{" "}
                <strong className="text-danger">
                  (Ancho: 2600px Alto: 800)
                </strong>
              </span>
              <input
                type="file"
                accept="image/*"
                {...register("img_large", {
                  required:
                    !modeEdit &&
                    "El campo Imagen pantallas grandes es obligatorio",
                })}
                className="hidden-input"
              />
              {errors.img_large && (
                <span className="text-danger">{errors.img_large.message}</span>
              )}
            </label>
            {prevImageLarge instanceof FileList && prevImageLarge.length > 0 ? (
              <img
                src={URL.createObjectURL(prevImageLarge[0])}
                alt="imagen screen large banner"
                className="upload-image"
              />
            ) : (
              modeEdit && (
                <img
                  src={bannerSelect.img_large}
                  alt="imagen screen large banner"
                  className="upload-image"
                />
              )
            )}
          </div>
        </section>
        <div className="container-link">
          <label htmlFor="">Link banner</label>
          <input className="link-input" {...register("link")} />
        </div>
        <div className="container-link">
          <label htmlFor="">Nombre</label>
          <input className="link-input" {...register("name")} />
        </div>
        <div className="container-link">
          <label htmlFor="">Orden</label>
          <input type="number" className="link-input" {...register("order")} />
        </div>

        <button
          className="btn-login"
          style={{ width: "100%", padding: ".6em" }}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          ) : modeEdit ? (
            "Finalizar edición"
          ) : (
            "Crear Banner"
          )}
        </button>
      </form>
    </div>
  );
};

export default CreateBanner;
