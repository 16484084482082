import React, { useEffect, useState } from "react";
import { createQuestion, getQuestions } from "services/questions";
import toast from "react-hot-toast";
import { updateQuestion } from "services/questions";
import TiptapEditor from "../components/common/TipTapEditor";

const QuestionsForm = ({ modeEdit = false, itemSelect, closeModalEdit }) => {
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    categoria: "",
    pregunta: "",
    respuesta: "",
  });
  const [newCategory, setNewCategory] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (modeEdit) {
      setNewQuestion({
        categoria: itemSelect.categoria,
        pregunta: itemSelect.pregunta,
        respuesta: itemSelect.respuesta,
      });
    }
  }, [modeEdit, itemSelect]);

  useEffect(() => {
    const handleFetchCategories = async () => {
      try {
        const { data } = await getQuestions();
        const newCategories = [
          ...new Set([...categories, ...data.map((item) => item.categoria)]),
        ];
        setCategories(newCategories);
      } catch (error) {
        console.error("Error al obtener las categorías", error);
      }
    };

    handleFetchCategories();
  }, []);

  // Función para manejar el cambio de categoría
  const handleCategoryChange = (e) => {
    setNewCategory(e.target.value);
  };

  // Función para agregar una nueva categoría
  const addCategory = () => {
    if (newCategory.trim() && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
      setNewCategory("");
      setOpenAddCategory(false);
      toast.success("Categoría agregada exitosamente");
    }
  };

  // Función para crear una nueva pregunta
  const handleCreateQuestion = async () => {
    if (modeEdit) {
      await updateQuestion(itemSelect.id, newQuestion);
      closeModalEdit();
      toast.success("Pregunta actualizada exitosamente");
    } else {
      if (
        newQuestion.categoria &&
        newQuestion.pregunta &&
        newQuestion.respuesta
      ) {
        await createQuestion(newQuestion);
        setNewQuestion({ categoria: "", pregunta: "", respuesta: "" });
        toast.success("Pregunta registrada exitosamente");
      }
    }
  };

  // Función para manejar el cambio de valores de la nueva pregunta
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputChange2 = (name, value) => {
    setNewQuestion({
      ...newQuestion,
      [name]: value,
    });
  };
  const handleCreateQuestion2 = () => {
    console.log("Pregunta enviada:", newQuestion);
    // Aquí puedes enviar la pregunta y la respuesta a la API
  };

  return (
    <>
      <h3 className="title-policy fw-bold text-center">
        {modeEdit ? "ACTUALIZAR" : "AGREGAR "} PREGUNTA
      </h3>
      <article className="container-affiliates">
        <div>
          <div className="separator mb-4">
            <hr className="line" />
            <p>Sección preguntas frecuentes</p>
            <hr className="line" />
          </div>
          <img
            className="image-section-about"
            src="/images/instagram.png"
            alt="imagen sección nosotros"
          />
        </div>
        <section>
          {openAddCategory && (
            <div className="container-input-tiptap">
              <label htmlFor="newcategory">Nueva categoría:</label>
              <input
                type="text"
                name="newcategory"
                value={newCategory}
                onChange={handleCategoryChange}
                placeholder="Nueva categoría"
                className="input-tiptap"
              />
              <button onClick={addCategory} className={`btn-about-contact`}>
                Agregar Categoría
              </button>
            </div>
          )}
          <div className="container-input-tiptap add mb-2">
            <select
              name="categoria"
              value={newQuestion.categoria}
              onChange={handleInputChange}
              className="input-tiptap"
            >
              <option value="">Seleccione una categoría</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>

            <button
              className="btn-add-category"
              onClick={() => setOpenAddCategory(!openAddCategory)}
            >
              {openAddCategory ? "-" : "+"}
            </button>
          </div>
          <label htmlFor="pregunta">Pregunta:</label>
          <TiptapEditor
            value={newQuestion.pregunta}
            onChange={(value) => handleInputChange2("pregunta", value)}
            // onChange={handleInputChange}
            placeholder="Escribe tu pregunta"
          />
          <label htmlFor="respuesta">Respuesta:</label>
          <TiptapEditor
            value={newQuestion.respuesta}
            onChange={(value) => handleInputChange2("respuesta", value)}
            // onChange={handleInputChange}
            placeholder="Escribe tu respuesta"
          />
          <button
            className={`btn-admin-defect `}
            onClick={handleCreateQuestion}
          >
            {modeEdit ? "Actualizar Pregunta" : "Crear Pregunta"}
          </button>
        </section>
      </article>
    </>
  );
};

export default QuestionsForm;
