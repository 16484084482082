import React, { useEffect, useState } from "react";
import Loaging from "common/Loaging";
import { NavLink } from "react-router-dom";
import Table from "../components/common/Tablet";
import { getVideoInstagramGac, deleteHistory } from "services/instagram";
import InstagramForm from "./InstagramForm";

const columns = [
  { key: "id", label: "#" },
  { key: "code", label: "Código", isVideoInstagran: true },
];

export const PageInstagram = () => {
  const [codeInstagram, setCodeInstagram] = useState(null);

  const listCode = async () => {
    try {
      const { data } = await getVideoInstagramGac();
      setCodeInstagram(data);
    } catch (error) {
      console.log(error);
      alert("No se pudo obtener el listado de historias de instagram");
    }
  };

  const handleDeleteVideo = async (id) => {
    try {
      await deleteHistory(id);
      await listCode();
    } catch (error) {
      console.log("error handleDeleteVideo", error);
    }
  };

  useEffect(() => {
    listCode();
  }, []);

  return (
    <article>
      <h1 className="centrar">Lista de videos de instagram</h1>
      <NavLink
        className="btn-about-contact btn-add-banner"
        to="/d77cdf4f4ab48f705fbed94d26c7ee03831add4b9cbe2a4c7c578f8cc7361215/history-instagram"
      >
        Agregar historia de instagram
      </NavLink>

      {!codeInstagram ? (
        <Loaging />
      ) : (
        <Table
          columns={columns}
          data={codeInstagram}
          onEdit={() => console.log("ACTION")}
          onDelete={handleDeleteVideo}
          Component={InstagramForm}
        />
      )}
    </article>
  );
};
