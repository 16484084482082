import React, { useState } from "react";
import { ReactComponent as IconDelete } from "assets/icons/components/admin/delete.svg";
import { ReactComponent as IconEdit } from "assets/icons/components/admin/edit-button.svg";
import { ReactComponent as CloseIcon } from "assets/icons/components/search/close.svg";
import { useModalGac } from "hooks/useModalGac";
import CustomModal from "common/CustomModal";
import { deleteTyc } from "services/tyc.service";
import toast from "react-hot-toast";
import TipTap from "components/editor-tiptap/TipTap";

const TableTyc = ({ items, listTyc }) => {
  const [isOpenModal, openModal, closeModal] = useModalGac(false);
  const [isOpenModalEdit, openModalEdit, closeModalEdit] = useModalGac(false);
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState(null);

  const handleOptions = async (opt = "", itemSelect) => {
    setItem(itemSelect);

    switch (opt) {
      case "Edit":
        openModalEdit();
        break;
      case "Delete":
        openModal();
        break;

      default:
        break;
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      await deleteTyc(id);
      toast.success("El banner se elimino correctamente");
      listTyc();
      closeModal();
    } catch (error) {
      console.error("No se pudo eliminar el banner", error);
      toast.error(`No se pudo eliminar el banner ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="term-list">
        {items?.map((term) => (
          <div key={term.id} className="term-card">
            <div className="term-header">
              <h3 className="term-title">{term.titulo}</h3>
              <div className="action-buttons">
                <button
                  className="btn-tyc edit"
                  onClick={() => handleOptions("Edit", term)}
                >
                  <IconEdit />
                </button>
                <button
                  className="btn-tyc"
                  onClick={() => handleOptions("Delete", term.id)}
                >
                  <IconDelete />
                </button>
              </div>
            </div>

            <div
              className="term-content"
              dangerouslySetInnerHTML={{ __html: term.texto }}
            ></div>

            <div className="term-footer">
              <span className="status active">
                Creado: {new Date(term.createdAt).toLocaleDateString()}
              </span>
            </div>
          </div>
        ))}
      </div>

      <CustomModal
        className={`modalGac ${isOpenModal && "is-open"}`}
        isOpen={isOpenModal}
        closeModal={closeModal}
      >
        <article className="card-delete text-center">
          <CloseIcon className="icon-modal-close" onClick={closeModal} />
          <h5>Eliminar item</h5>
          <p>¿Estás seguro de que deseas eliminar este item?</p>
          <button className="btn-action cancel" onClick={closeModal}>
            No, Cancelar
          </button>
          <button className="btn-action" onClick={() => handleDelete(item)}>
            {isLoading ? (
              <div
                className="spinner-border spinner-border-sm"
                role="status"
              ></div>
            ) : (
              "Si, eliminar"
            )}
          </button>
        </article>
      </CustomModal>

      <CustomModal
        className={`modalGac ${isOpenModalEdit && "is-open"}`}
        isOpen={isOpenModalEdit}
        closeModal={closeModalEdit}
      >
        <article className="card-delete">
          <CloseIcon className="icon-modal-close" onClick={closeModalEdit} />
          <h1 className="centrar title__tyc mb-3">
            Actualizar Términos y condiciones
          </h1>

          <TipTap
            item={item}
            modeEdit={true}
            listTyc={listTyc}
            closeModalEdit={closeModalEdit}
          />
        </article>
      </CustomModal>
    </>
  );
};

export default TableTyc;
