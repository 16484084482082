import React from "react";
import sedes from "assets/Points.json";
import BannerText from "common/BannerText";
import Testimonials from "common/Testimonials";
import OutlinedText from "common/OutlinedText";
import Slider from "components/video-slider/Slider";
import ToSubscribe from "components/contact/ToSubscribe";
import CareChannels from "components/contact/CareChannels";
import Image from "assets/images/questions/contactanos.webp";
import "styles/contact.css";
import { ScrollAnimations } from "hooks/useScrollAnimations";

const Contact = () => {
  return (
    <ScrollAnimations>
      <BannerText Image={Image}>
        <OutlinedText
          text1="CONTÁCTANOS"
          text2="CONTÁCTANOS"
          color="#fff"
          bottom_text1="27%"
          left_text1="9%"
          bottom_text2="30%"
          left_text2="7%"
        />
      </BannerText>

      <div
        className="container-questions"
        style={{
          height: "20vh",
        }}
      >
        <OutlinedText
          positionTop1="30px"
          left_text1="10%"
          text1="Oficinas"
          text2="Oficinas"
          color="var(--azul-gac)"
          positionTop2="0px"
          left_text2="7%"
        />
      </div>

      <Slider showIndicators={true} showButtons={true}>
        {sedes.map((point, index) => (
          <div className="slide-item" key={index}>
            <div>
              <span className="title">{point?.sede}</span>
            </div>
            <div>
              <span className="text-yellow">Dirección:</span>
              <span>{point?.direccion.toLowerCase()}</span>
            </div>
            <div>
              <span className="text-yellow">Ubicación:</span>
              <span>{point?.ubicacion}</span>
            </div>
            <div>
              <span className="text-yellow">Descripción:</span>
              <span>{point?.direccion.toLowerCase()}</span>
            </div>
          </div>
        ))}
      </Slider>
      <CareChannels />
      <ToSubscribe />
      <Testimonials />
    </ScrollAnimations>
  );
};

export default Contact;
