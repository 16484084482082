import React from "react";
import CardOption from "./components/common/CardOption";
import "styles/dashboardAdmin/manager-content.css";

const listOptions = [
  {
    id: "f2581a44-5aa2-40b3-8d86-cee02cf28340",
    titulo: "Crear Banner",
    descripcion: "Añadir un nuevo banner visual a la página principal.",
    path: "banners",
  },
  {
    id: "b59ae260-3a63-4e86-a786-d264640ad187",
    titulo: "Crear valor",
    descripcion: "Añadir una nueva valor a la seccion de afiliados ",
    path: "dashboard-affiliates",
  },
  {
    id: "4017ffbc-1c1d-4369-b09a-8f4d10d73b70",
    titulo: "Agregar video de instagram",
    descripcion:
      "Se agrega un video de instagram a la sección de preguntas frecuentes",
    path: "instagram",
  },
  {
    id: "ec8cdbd3-0384-41d5-936a-f04aa4f2d43e",
    titulo: "Agregar testimonio",
    descripcion: "Se agrega un testimonio a la sección de testimonios",
    path: "testimonials",
  },
  {
    id: "21c2dc5a-282e-42ab-9349-44220b6c351e",
    titulo: "Agregar pregunta",
    descripcion: "Se agrega una pregunta a la sección de preguntas frecuentes",
    path: "questions",
  },
  {
    id: "21c2dc5a-282e-42ab-9349-44220b6c251e",
    titulo: "Agregar Términos y condiciones",
    descripcion: "Se agregan un términos y condiciones",
    path: "manage-content-tyc",
  },
];

const ManagerContent = () => {
  return (
    <>
      <h2 className="title-policy text-center">Menú de Administración</h2>
      <div className="menu-admin">
        {listOptions.map(({ id, titulo, descripcion, path }) => (
          <CardOption
            key={id}
            titulo={titulo}
            descripcion={descripcion}
            route={path}
          />
        ))}
      </div>
    </>
  );
};

export default ManagerContent;
