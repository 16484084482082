import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { createAuditory } from "services/auditory.service";
import { getValueMora } from "services/Payments";
import { getUserInfo } from "services/Payments";

const ValidateUser = ({ setFormConfig, setUser, setMora }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const OnSubmit = handleSubmit(async (data) => {
    try {
      const response = await getUserInfo(data);
      if (response.status === 500) {
        return toast.error(
          "Usuario no encontrado, por favor intetelo de nuevo"
        );
      }
      setUser(response);
      console.log(response);

      await createAuditory({
        contrato: response.contrato || "",
        titular: response.titular || "",
        email: response.email || "no_tiene@correo.com",
        pagina: "pagos",
      });
      const resp = await getValueMora(response.codigo);
      setMora(resp);
      setFormConfig({ step: 2 });
    } catch (error) {
      toast(
        <section>
          <header>
            <h2>¡Atención!</h2>
          </header>
          <p>
            Parece que no tenemos un correo electrónico registrado con tu cédula
            de ciudadanía. Para poder continuar, por favor, contáctanos a
            nuestro soporte a través de WhatsApp haciendo clic en el siguiente
            enlace:
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=573156935204&text=Buen%20d%C3%ADa%20%20%0ARequiero%20ayuda"
            target="_blank"
            role="button"
            aria-label="Contactar a soporte por WhatsApp"
            rel="noopener noreferrer"
          >
            Contactar a Soporte
          </a>
          <p>
            {" "}
            Nuestro equipo estará encantado de ayudarte a resolver cualquier
            inconveniente.
          </p>
        </section>,
        {
          duration: 60000,
        }
      );

      console.log(error);
    }
  });

  return (
    <form onSubmit={OnSubmit}>
      <div className={errors?.cedula && "ErrorContainer"}>
        <label htmlFor="cedula">Ingrese su número de identificación</label>
        <input
          className="inputPay"
          {...register("cedula", {
            required: {
              value: true,
              message: "Debe ingresar su número de identificación",
            },
          })}
        />
        {errors?.cedula && (
          <span className="errorForm">
            <i className="fa-solid fa-circle-exclamation"></i>
            {errors?.cedula.message}
          </span>
        )}
      </div>

      <button disabled={isSubmitting} className="MercadoPago mt-3">
        {isSubmitting ? (
          <span className="spinner-border spinner-border-sm"></span>
        ) : (
          "Consultar"
        )}
      </button>
    </form>
  );
};

export default ValidateUser;
