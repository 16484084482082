import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getMarksGac } from "services/marks";
import { deleteMark } from "services/marks";
import Loaging from "common/Loaging";
import Table from "../components/common/Tablet";
import AddValueAffiliates from "./AddValueAffiliates";

const columns = [
  { key: "id", label: "#" },
  { key: "icono", label: "Icono", isImage: true },
  { key: "titulo", label: "Titulo" },
  { key: "valor", label: "cifra" },
];
const DashboardAffiliates = () => {
  const [DataList, setDataList] = useState(null);

  const getServiceMarks = async () => {
    try {
      const { data } = await getMarksGac();
      setDataList(await data);
    } catch (error) {}
  };
  const handleDeleteMark = async (id) => {
    try {
      await deleteMark(id);
      await getServiceMarks();
    } catch (error) {
      console.log("error handleDeleteMark", error);
    }
  };

  useEffect(() => {
    getServiceMarks();
  }, []);

  return (
    <div>
      <h1 className="centrar">Lista de valores afiliados</h1>
      <NavLink
        className="btn-about-contact btn-add-banner"
        to="/d77cdf4f4ab48f705fbed94d26c7ee03831add4b9cbe2a4c7c578f8cc7361215/add-value-affiliates"
      >
        Agregar cifra
      </NavLink>

      {!DataList ? (
        <Loaging />
      ) : (
        <Table
          columns={columns}
          data={DataList}
          onEdit={() => console.log("ACTION")}
          onDelete={handleDeleteMark}
          Component={AddValueAffiliates}
        />
      )}
    </div>
  );
};

export default DashboardAffiliates;
