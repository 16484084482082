import React, { useState } from "react";
import blogs from "assets/Blogs.json";
import { NavLink } from "react-router-dom";
import Search from "components/Search/Search";
import BlogIndex from "components/blog/BlogIndex";
import "swiper/css";
import "styles/blog.css";
import { AnimatedSection, ScrollAnimations } from "hooks/useScrollAnimations";
const Blogs = () => {
  const [search, setSearch] = useState("");
  const [activeCategory, setActiveCategory] = useState(null);

  const filterBlogs = () => {
    const searchWords = search
      .split(",")
      .map((word) => word.trim().toLowerCase());
    if (searchWords.length === 0) {
      return blogs.slice();
    }
    const filtered = blogs.filter((blog) => {
      const blogKeywords = blog.keywords
        .split(",")
        .map((keyword) => keyword.trim().toLowerCase());
      const blogText = blog.text.toLowerCase();
      return searchWords.some(
        (word) =>
          blog.title.toLowerCase().includes(word) ||
          blogKeywords.includes(word) ||
          blogText.includes(word)
      );
    });

    return filtered.slice();
  };

  const handleCategory = (category, subcategory) => {
    setSearch(subcategory);
    setActiveCategory(category);
  };

  return (
    <ScrollAnimations>
      <section className="layout-card-blog">
        {filterBlogs()
          ?.slice(0, 3)
          .map((blog, index) => (
            <NavLink
              to={`/blog/${blog.id}`}
              key={blog.id}
              className={"card-blog"}
            >
              <img src={blog.img[0].img} alt={blog.title} />
              <h4
                className={`text-title-blog ${
                  index === 1 ? "second" : index === 2 && "third"
                }`}
              >
                {blog.title}
              </h4>
            </NavLink>
          ))}
      </section>
      <AnimatedSection className="slide-up">
        <section className="layout-grid-blogs">
          <div className="add-row3">
            <Search search={search} setSearch={setSearch} />
            <BlogIndex
              activeCategory={activeCategory}
              handleCategory={handleCategory}
            />
          </div>
          {filterBlogs()
            ?.slice(0, 6)
            .map((blog, index) => (
              <NavLink
                to={`/blog/${blog.id}`}
                key={blog.id}
                className={
                  "item-blog " +
                  (index === 0 || index === 2 || index === 4
                    ? "add-col2"
                    : index === 1 && "add-row2")
                }
              >
                <img src={blog.img[0].img} alt={blog.title} />
                <h6 className="title-blog-item">{blog.title}</h6>
              </NavLink>
            ))}
        </section>
      </AnimatedSection>
      <AnimatedSection className="fade-in">
        <section className="layout-grid-blogs second">
          {filterBlogs()
            ?.slice(6)
            .map((blog, index) => (
              <NavLink
                to={`/blog/${blog.id}`}
                key={blog.id}
                className={
                  "item-blog " +
                  (index === 1 || index === 4 || index === 6 ? "add-col2" : "")
                }
              >
                <img src={blog.img[0].img} alt={blog.title} />
                <h6 className="title-blog-item">{blog.title}</h6>
              </NavLink>
            ))}
        </section>
      </AnimatedSection>
    </ScrollAnimations>
  );
};

export default Blogs;
