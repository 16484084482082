import React from "react";

const FaqQuestions = ({ category, onClick, isActive }) => {
  console.log(category);

  return (
    <div
      className={`category ${isActive ? "active" : ""}`}
      onClick={() => onClick(category)}
    >
      {category}
    </div>
  );
};

export default FaqQuestions;
